import styled from "styled-components";
import MassHumanitiesLogo from '../../images/massHumanitiesLogo.png'

const About = () => {
    return (
        <Wrapper>
            <ContentWrapper>
                <Header>
                    <Rowdies>ABOUT</Rowdies>
                    <Noto>關於我們</Noto>
                </Header>
                <SubHeader>
                    What is the Immigrant History Trail?
                </SubHeader>
                <Content>
                    The Immigrant History Trail is a multimedia public art project that activates Boston Chinatown’s community archives to share stories about the neighborhood’s working class immigrant histories.
                </Content>
                <Content>
                    Plaques will hang on the walls of buildings across Boston Chinatown, inviting visitors to navigate the neighborhood with curiosity. QR codes link to this website, which highlights relevant text, images, and audio fragments, and points to where visitors can learn more.
                </Content>
                <Content>
                    This project is a community stewarded resource that will be updated regularly with new contributions of sites and stories for the Immigrant History Trail.
                </Content>
                <SubHeader>
                    Why?
                </SubHeader>
                <Content>
                    Boston Chinatown is at a crossroads after absorbing decades of urban renewal, zoning injustice, and institutional expansion, followed by luxury highrise development. The future of the immigrant, working -class residential and small business core that has characterized the neighborhood for 180 years is at risk. Now, more than ever, there is a need to make working -class immigrant histories of Chinatown known, accessible, and celebrated.
                </Content>
                <SubHeader>
                    How?
                </SubHeader>
                <Content>
                    Boston has a long history of neighborhood activism, and the Immigrant History Trail involves a similar grassroots and iterative mentality. We will engage local community members to collaborate with us as advisors and participants by contributing stories and memories, selecting sites for the Trail, creating archival resources, and more. We are committed to creating a participatory, non-linear Trail that emerges from community involvement. Residents, workers, small business owners, and organizers can all contribute stories, research, and audiovisual media, and engage their fellow community members.
                </Content>
                <SubHeader>
                    Who?
                </SubHeader>
                <Content>
                    We would like to thank Mass Humanities and the City of Boston Transformative Public Art Grant for support. We would also like to thank our partners including Boston Research Center and all current and past volunteer Immigrant History Trail Committee members.
                </Content>
                <Content>
                    While the Chinatown Community Land Trust is launching the Immigrant History Trail, the project idea originated during a conversation among many community partners held at the Chinese Historical Society of New England (CHSNE) in 2019, as community members discussed historic preservation for the 2020 Chinatown Master Plan. We would like to honor Tunney Lee (1931-2020), who was present in this conversation and enthusiastic about creating an iterative QR-code trail to show Chinatown’s archival images to its visitors.
                </Content>
                <Content>
                    The core Immigrant History Trail team includes Daphne Xu, Lydia Lowe, Vivian WuWong, Lily Xie, Elisha Zhao, Franny Xi Wu. The website team includes Eric Han, Wei Qi, Diane Zhou. Fabrication and installation of physical signage is by Jaywalk Studio. The Immigrant History Trail has a volunteer Advisory Committee which works to continue building the Trail.
                </Content>
                <Header style={{marginTop: '36px'}}>
                    <Noto>關於我們</Noto>
                </Header>
                <SubHeader>
                    <Noto>
                        移民歷史徑是什麼?
                    </Noto>
                </SubHeader>
                <Content>
                    <Noto>
                        移民歷史徑是一個多媒體公共藝術項目,透過喚醒波士頓華埠的社區檔案,分享這個社區內工薪階級移民的歷史故事。
                    </Noto>
                </Content>
                <Content>
                    <Noto>
                        我們將在華埠各建築物的牆面上掛起牌匾,邀請訪客帶著好奇心探索這個街區。掃描牆上的二維碼就可以跳轉到網站,閱覽相關的文章、圖片和音頻片段,並指引訪客了解更多資訊。我們將在啟動儀式上進行四個牌匾的步行導覽。
                    </Noto>
                </Content>
                <Content>
                    <Noto>
                        這個項目是一個由社區管理的資源,將會定期更新、加入新的地點和故事,豐富移民歷史徑。
                    </Noto>
                </Content>
                <SubHeader>
                    <Noto>
                        為什麼有移民歷史徑?
                    </Noto>
                </SubHeader>
                <Content>
                <Noto>
                    在數十年的現代化、不公平地區劃分、大型機構擴
                    展後，又迎來了豪華高端化發展，波士頓華端口正
                    走到屬於它的分岔路口。在過去108年建造起華埠
                    靈魂的勞工階級住所和小生意核心地帶，還有移民
                    的未來，全都面臨危機。與過去任何時候相比，當下
                    便是最需要讓大眾學習、得到和慶祝勞工階級移民
                    歷史的重要時刻。
                </Noto>
                </Content>
                <SubHeader>
                    <Noto>
                        如何建造移民歷史徑?
                    </Noto>
                </SubHeader>
                <Content>
                <Noto>
                    波士頓擁有悠久的社區運動歷史，而移民歷史徑則
                    擁有相似且迭代的草根精神。我們會和社區本地成
                    員合作，擔任顧問或參與者的角色，分享他們的故
                    事、為小徑選址、創造檔案資源等等。我們承諾這將
                    是一個富有參與性、非直線、由社區內誕生的小徑。
                    居民、工人、小生意持有者和組織者通通都可以奉
                    獻自己的故事、研究、影音媒體和邀請他們的同伴
                    來參與。
                </Noto>
                </Content>
                <SubHeader>
                    <Noto>
                        誰創造了移民歷史徑?
                    </Noto>
                </SubHeader>
                <Content>
                    <Noto>
                        我們衷心感謝麻省人道協會和波士頓市政府改革性
                        公共藝術的補助支持。我們也衷心感謝我們的搭檔
                        ，包括波士頓研究中心和參與過或正在參與移民歷
                        史小徑工作小組的志工們。 
                    </Noto>
                </Content>
                <Content>
                    <Noto>
                        雖然華端口土地信託會負責執行移民歷史徑，整個
                        計畫想法其實來自於2019年新英格蘭地帶華人歷史
                        社會內的一次社區搭檔對話，當時社區成員們正在
                        討論2020華埠十年計劃內的保留歷史部分。我們將
                        會銘記Tunney Lee(1931-2020)在這次討論中表現
                        出來對創造為訪客展示華埠存檔照片迭代二維碼的
                        巨大熱情。
                    </Noto>
                </Content>
                <Content>
                    <Noto>
                        移民歷史徑的主要成員包括 Daphne Xu, Lydia Lowe,
                        Vivian WuWong, Lily Xie，Elisha Zhao, Franny Xi Wu。創建網站小組包括E ric
                        Han, Wei Qi, Diane Zhou。實體招牌的組裝與設立來
                        自Jaywalk工作室。移民歷史小徑有一個繼續建立小
                        徑的顧問志工小組。
                    </Noto>
                </Content>
                <Border/>
                <Sponsers>
                    <img src={MassHumanitiesLogo} alt='mass humanities logo'/>
                </Sponsers>
            </ContentWrapper>
            <ContentWrapper>
                <SubHeader>
                    Acknowledgements
                </SubHeader>
                <Content>
                    The Immigrant History Trail is funded in part by the Mayor’s Office of Arts and Culture of the City of Boston, Mass Humanities with funding from the Barr Foundation, and by a grant from the National Trust Preservation Fund of the National Trust for Historic Preservation.
                </Content>
                <Content>
                    Thank you to the members of the Immigrant History Trail Committee which advised the project, conducted research, wrote and edited text, found photographs, and performed many other tasks to begin building the trail: <br />
                    Christina Chan<br />
                    Krista Chan<br />
                    Collyn Chan<br />
                    Chutze Chou<br />
                    Stephanie Fan<br />
                    Maria Fong<br />
                    Olivia Fong<br />
                    Dory Klein<br />
                    Henry Ko<br />
                    Michael Liu<br />
                    Nancy Lo<br />
                    Lydia Lowe<br />
                    Feronia Mei<br />
                    Joseph Nevins<br />
                    Franny Xi Wu<br />
                    Jean Wu<br />
                    Vivian WuWong<br />
                    Lily Xie<br />
                    Daphne Xu<br />
                    Hongyan Yang<br />
                    Cynthia Yee<br />
                    Kenneth Yee<br />
                    Anita Yip<br />
                    Elisha Zhao
                </Content>
                <Content>
                    Thank you to Alice Kane and the Chinese Historical Society of New England; Kenneth Eng, Nancy Lo, and the Chinese Consolidated Benevolent Association; Dory Klein and the Boston Public Library; Giordana Mecagni and the Northeastern University Archives; Chloe Bordewich and Lydia Harrington of the Little Syria Project; and the Pao Arts Center for their partnership, advice, support, and sharing of resources as we developed the Immigrant History Trail.
                </Content>
                <Content>
                    Thank you to the interviewees who have contributed time to build and share their oral histories:<br /> 
                    Joanne Bethoney Carter<br />
                    Eva Peter Chojnowski<br />
                    Betty Lutfy Dimeco<br />
                    Russell Eng<br />
                    Ann Moy<br />
                    Brian Moy<br />
                    Marie Moy<br />
                    George Salah<br />
                    Rosanne Solomon<br />
                    Jianhua Tang<br />
                    Jeffrey Wong<br />
                    Cynthia Yee<br />
                </Content>
                <Content>
                    Thank you to Professor Hongyan Yang and her Boston College course Making American Landscapes: Building and Living in Asian America for extensive support in researching the following sites.<br />
                    25-29 Beach Street:<br />
                    Ines Angara<br />
                    Hamilton A Areddy<br />
                    Jennifer L Bartlett<br />
                    Cassandra J Kavanagh<br />
                    <br />
                    Chinese Merchants Association:<br />
                    Maxwell K. Guan<br />
                    Ashton Kao<br />
                    Dan T. Nguyen<br />
                    Ethan Sun<br />
                    <br />
                    Globe Theater:<br />
                    Ric J. DeLyons<br />
                    Kaetlynn C. King-Velez<br />
                    Mary E. Zagrocki<br />
                    <br />
                    Hong Far Low Restaurant:<br />
                    Shirley J. Canales<br />
                    Kiran Hebert<br />
                    Claire Kildahl<br />
                    Aina Yamagishi<br />
                    <br />
                    John Assaf Home:<br />
                    Connor J. Capshaw<br />
                    Emma J. Cunningham<br />
                    Nicholas R. Rhodes<br />
                    Katelynn Wasta<br />
                    <br />
                    Kwong Kow Chinese School:<br />
                    Alisa G. Cufone<br />
                    Grace L. Concannon<br />
                    Hi’ilei G. Leong<br />
                    Kendall Torres<br />
                    <br />
                    Maryknoll Sisters Center:<br />
                    Isabella M. Ayalew<br />
                    Josiah S. Odhiambo<br />
                    Mica Quelle<br />
                    Saifya N. Stewart<br />
                    <br />
                    Shakespeare Inn:<br />
                    Jeremiah Alhassan<br />
                    Tommy Bi<br />
                    Ashley S. Choi<br />
                    Jenna Jiang<br />
                    Sofia Rugova<br />
                    <br />
                    Tunney Lee Home:<br />
                    Chelsea Lee<br />
                    Madeline G. Cunniff<br />
                    Mae S. Carroll<br />
                    Sophia C. Comparato
                </Content>
                <Content>
                    Thank you to Giselle Chu, Emily Hiltunen, and Rachel Liu, project assistants for the class. Thank you to their following advisors: Eddie Bonilla, Lynn Johnson, Michael Liu, Lydia Lowe, Steve Runge, Erin Scheopner, Vivian WuWong.
                </Content>
            </ContentWrapper>
        </Wrapper>
    )
}

const Noto = styled.div`
    font-family: 'Noto Serif TC', sans-serif;
    color: inherit;
    font-weight: inherit;
`

const Rowdies = styled.div`
    font-family: 'Rowdies', sans-serif;
`

const Sponsers = styled.div`
    font-family: 'Rowdies';
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #423F67;

    img {
        height: 100px;
    }
`

const Border = styled.div`
    margin: 40px 0px;
    height: 1px;
    width: 100%;

    background: rgba(66, 63, 103, 0.25);
`

const Content = styled.div`
    margin-top: 16px;

    font-family: ${props => props.font === 'Noto Serif TC' ? 'Noto Serif TC' : 'Quattrocento'};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */


    color: #1E1E1E;

`

const SubHeader = styled.div`
    margin-top: 8px;

    font-family: ${props => props.font === 'Noto Serif TC' ? 'Noto Serif TC' : 'Rowdies'};
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: #423F67;
    margin: 24px 0px;
`

const Header = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 24px;

    gap:6px;
    display: flex;
    flex-direction: column;

    text-transform: uppercase;

    color: #423F67;

    div:not(:first-child) {
        font-weight: 800;
    }
`

const Wrapper = styled.div`
    background-color: #FBF6E9;
    box-sizing: border-box;
    padding: 33px;
    display: flex;
    justify-content: center;
`

const ContentWrapper = styled.div`
    margin-top: 33px;
    padding: 32px;
    width: 900px;
    display: flex;

    flex-direction: column;
    box-sizing: border-box;

    @media(max-width: 600px) {
        margin-top: 0px;
        padding: 0px;
    }
`

export default About;