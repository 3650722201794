import styled from 'styled-components';
import LandmarkList from './Components/LandmarkList';
import HistoryTrail from './Components/HistoryTrail';
import HistoryTrailMobile from './Components/HistoryTrailMobile';
import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Map from '../Map/Map';
import { useRecoilState } from 'recoil';
import { trailValues } from '../../state/state';
import Media from 'react-media';

const Directory = ({data}) => {
    const [trail, setTrail] = useState([]);
    const [isHistoryTrailOpen, setHistoryTrailOpen] = useState(false);
    const [landmarkHovered, setLandmarkHovered] = useState(null);
    const [trailState, setTrailState] = useRecoilState(trailValues);

    const dragEnd = (result) => {
        if(!result.destination) return;
        if(!result.destination.droppableId) return;

        if(result.source.droppableId === 'scrollingMenuId') {
            const i = result.destination.index;
            const landmarks = [...trail];
            let foundLandmark = data.find(landmark => landmark.id === result.draggableId);

            if (trail.includes(foundLandmark)) return;
            landmarks.splice(i, 0, foundLandmark);
            setTrail(landmarks); 

        } else if (result.source.droppableId === 'landmarkContainerDrop') {
            const startIndex = result.source.index;
            const endIndex = result.destination.index;

            setTrail((trail) => {
                const landmarks = [...trail];
                const [removed] = landmarks.splice(startIndex, 1);
                landmarks.splice(endIndex, 0, removed);
                return landmarks;
              });
        }
    }

    const isLandmarkInTrail = (landmark) => {
        return trail.includes(landmark)
    }

    const removeFromTrail = (data) => {
        const tempArray = trail.filter(landmark => landmark !== data)
        setTrail(tempArray);
    }

    const addToTrail = (data, trail) => {
        if(isLandmarkInTrail(data)) {
            return
        } else {
            setTrail([...trail, data])
        }
    }

    useEffect(() => {
        if(trail.length === 0) return;

        if (!isHistoryTrailOpen) {
            setHistoryTrailOpen(true)
        }
    }, [trail])

    useEffect(() => {
        setTrailState({historyTrail: trail});
    }, [trail])

    useEffect(() => {
        if(
            trailState.historyTrail.length > 0 &&
            trail.length === 0
        ) {
            setTrail(trailState.historyTrail);
        }
    }, [])

    return (
        <Media 
            queries={{
                small: "(max-width: 599px)",
                large: "(min-width: 600px)"
            }}
        >
            {matches => (
                <>
                    <DragDropContext
                        onDragEnd={dragEnd}
                    >
                        <Wrapper>
                            <LandmarkList
                                data={data}
                                landmarkHovered={landmarkHovered}
                                setLandmarkHovered={setLandmarkHovered}
                                isHistoryTrailOpen={isHistoryTrailOpen}
                                setHistoryTrailOpen={setHistoryTrailOpen}
                                trail={trail}
                                setTrail={setTrail}
                                isLandmarkInTrail={isLandmarkInTrail}
                                removeFromTrail={removeFromTrail}
                                matches={matches}
                            />
                            <Map 
                                data={data} 
                                landmarkHovered={landmarkHovered}
                                setLandmarkHovered={setLandmarkHovered}
                                isHistoryTrailOpen={isHistoryTrailOpen}
                            />
                                {matches.small && 
                                    <HistoryTrailMobile
                                        trail={trail}
                                        setTrail={setTrail}
                                        isLandmarkInTrail={isLandmarkInTrail}
                                        removeFromTrail={removeFromTrail}
                                        isOpen={isHistoryTrailOpen}
                                        setOpen={setHistoryTrailOpen}
                                        addToTrail={addToTrail}
                                    />
                                }
                                {matches.large && 
                                    <HistoryTrail
                                        trail={trail}
                                        setTrail={setTrail}
                                        isLandmarkInTrail={isLandmarkInTrail}
                                        removeFromTrail={removeFromTrail}
                                        isOpen={isHistoryTrailOpen}
                                        setOpen={setHistoryTrailOpen}
                                        addToTrail={addToTrail}
                                    />
                                }
                        </Wrapper>
                    </DragDropContext>
                </>
            )}
        </Media>
    )
}

export default Directory;

const Wrapper = styled.div`
    display: flex;
    transition: all .12s ease-in-out;
`